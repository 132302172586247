<template>
  <v-container>
    <v-dialog v-model="$store.state.namesDialog">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <span class="headline">Edit Details</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab text @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select
            v-model="$store.state.userData.studentType"
            :items="studentTypes"
            label="Student Type"
            required
            class="pt-8"
          ></v-select>
          <v-text-field
            v-model="$store.state.userData.nameEN"
            label="Name EN"
            required
            class="pt-8"
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.nameGR"
            label="Name GR"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.am"
            label="AM"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.from"
            label="Start Date"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.to"
            label="End Date"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.residence"
            label="Residence"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.studentId"
            label="Student ID"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.uniDept"
            label="Department"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.uniAddr"
            label="University Address"
            required
          ></v-text-field>
          <v-text-field
            v-model="$store.state.userData.creationDate"
            label="Pass Creation Date"
            required
          ></v-text-field>
          <!-- dropdown -->
          <v-select
            v-model="$store.state.userData.uni"
            :items="universities"
            label="University"
            item-value="file"
            item-text="name"
          ></v-select>
          <upload-pic @save="save"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card rounded="xl" class="pt-3">
          <v-card-title class="pa-1 ma-0">
          <v-img contain :src='getImgUrl()' style="width:100%;height:150px;
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
          mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));"/>
          </v-card-title>
          <v-card-title  v-show='frontSide'>
            <v-row class="px-3">
              <p style="font-size: 16px" class='pt-4'>{{$store.state.userData.studentType}}</p>
              <v-spacer></v-spacer>
              <!-- base64 image from local storage picture -->
              <v-img v-if='$store.state.userData.picture' contain width="70px" :src="$store.state.userData.picture"></v-img>
              <v-img v-else src="@/assets/moi.jpg" contain width="70px"></v-img>
            </v-row>
          </v-card-title>
          <slide-up-down :active="frontSide" style="width:100%;">
          <v-card-text>
            <v-row class="text-left">
              <v-col>
                <strong>Date & Time</strong>
                <p class="ma-0">{{$store.state.userData.creationDate}}</p>
              </v-col>
              <v-col>
                <strong>Type</strong>
                <p class="ma-0">General</p>
              </v-col>
            </v-row>
            <v-row class="text-left">
              <v-col>
                <strong>ΑΚΑΔΗΜΑΪΚΗ ΤΑΥΤΟΤΗΤΑ / ACADEMIC ID</strong>
                <p class="ma-0">{{$store.state.userData.studentType}}</p>
              </v-col>
              <v-col>
                <strong>ΟΝΟΜΑΤΕΠΩΝΥΜΟ</strong>
                <p class="ma-0">{{$store.state.userData.nameGR}}</p>
              </v-col>
            </v-row>
            <v-row class="text-left">
              <v-col>
                <strong>ΗΜ/ΝΙΑ ΕΓΓΡΑΦΗΣ</strong>
                <p class="ma-0">{{$store.state.userData.from}}</p>
              </v-col>
              <v-col>
                <strong>ΔΕΛΤΙΟ ΕΙΣΙΤΗΡΙΟΥ</strong>
                <p class="ma-0">ΙΣΧΥΕΙ ΕΩΣ: {{$store.state.userData.to}}</p>
              </v-col>
            </v-row>
            <div class="mt-5" v-if="$store.state.showQR">
              <v-row class="justify-center">
                <VueQrcode :value="$store.state.userData.studentId" :options="{ width: '80vw' }"></VueQrcode>
              </v-row>
              <v-row class="justify-center ma-0">
                {{$store.state.userData.studentId}}
              </v-row>
            </div>
          </v-card-text>
          </slide-up-down>
          <slide-up-down :active="!frontSide" style="width:100%;">
          <v-card-text>
          <v-row class="text-left">
            <v-col>
              <strong>FULL NAME</strong>
              <p class="ma-0">{{$store.state.userData.nameEN}}</p>
            </v-col>
            <v-col>
              <strong>ΑΡΙΘΜΟΣ ΜΗΤΡΩΟΥ</strong>
              <p class="ma-0">{{$store.state.userData.am}}</p>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col>
              <strong>ΣΧΟΛΗ/ΤΜΗΜΑ</strong>
              <p class="ma-0">{{$store.state.userData.uniDept}}</p>
            </v-col>
            <v-col>
              <strong>ΕΔΡΑ ΣΧΟΛΗΣ/ΤΜΗΜΑΤΟΣ</strong>
              <p class="ma-0">{{$store.state.userData.uniAddr}}</p>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col>
              <strong>ΤΟΠΟΣ ΚΑΤΟΙΚΙΑΣ</strong>
              <p class="ma-0">{{$store.state.userData.residence}}</p>
            </v-col>
            <v-col>
              <strong>ERASMUS</strong>
              <p class="ma-0">ΟΧΙ</p>
            </v-col>
          </v-row>
          </v-card-text>
          </slide-up-down>
          <v-card-text>
            <v-row>
              <v-spacer/>
              <v-btn fab x-small class="mr-2 mb-2" @click="frontSide = !frontSide">
                <v-icon>mdi-rotate-3d-variant</v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'
import uploadPic from '@/components/uploadPic'
import VueQrcode from '@chenfengyuan/vue-qrcode';

  export default {
    name: 'Pass',
    components: {
      SlideUpDown,
      uploadPic,
      VueQrcode
    },
    computed: {
      userData() {
        return this.$store.state.userData 
      }
    },
    mounted () {
      // load data from local storage
      if (localStorage.getItem('userData')) {
        this.$store.state.userData = JSON.parse(localStorage.getItem('userData'))
      }else{
        localStorage.setItem('userData', JSON.stringify(this.$store.state.userData));
      }
    },
    methods: {
      getImgUrl() {
        if(this.$store.state.userData.uni){
          return require('@/assets/'+this.$store.state.userData.uni)
        }else{
          return require('@/assets/aueb.jpg')
        }
       
      },
      save(){
        this.$store.state.namesDialog = false;
        // emit event to parent
        this.$emit('save', this.$store.state.userData)
      }
    },
    // watch deep object
    watch: {
      userData: {
        handler() {
          // console.log('updated');
          // save data to local storage
          localStorage.setItem('userData', JSON.stringify(this.userData));
        },
        deep: true
      }
    },
    data: () => ({
      frontSide: true,
      settingsDialog: false,
      universities:[
        {'file':'aueb.jpg',
          'name':'AUEB'
        },
        {'file':'pada.jpg',
          'name':'ΠΑΔΑ'
        }
      ],
      studentTypes:[
        "ΠΡΟΠΤΥΧΙΑΚΟΣ ΦΟΙΤΗΤΗΣ",
        "ΜΕΤΑΠΤΥΧΙΑΚΟΣ ΦΟΙΤΗΤΗΣ"
      ],
      data:{
        studentType: 'ΠΡΟΠΤΥΧΙΑΚΟΣ ΦΟΙΤΗΤΗΣ',
        creationDate:"19.03.2022 14:27",
        nameGR: 'ΓΕΩΡΓΙΟΣ ΤΣΟΠΑΝΟΠΟΥΛΟΣ',
        nameEN: 'GEORGIOS TSONOPANOPOULOS',
        am: '3135123',
        from: '10/2018',
        to: '08/2025',
        residence: 'Δ. ΠΕΤΡΟΥΠΟΛΗΣ / Π.ΑΤΤΙΚΗΣ',
        uniAddr:'Δ. ΑΙΓΑΛΕΩ / Π. ΑΤΤΙΚΗΣ',
        uniDept:'ΗΛΕΚΤΡΟΛΟΓΩΝ ΚΑΙ ΗΛΕΚΤΡΟΝΙΚΩΝ ΜΗΧΑΝΙΚΩΝ',
        studentId: '212804001421',
        uni:'pada.jpg'
      }
    }),
  }
</script>
