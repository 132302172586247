<template>
    <v-container>
        <!-- upload a picture and save it as a variable -->
        <v-row>
            <v-col cols="12" class="text-center">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <v-file-input
                                    prepend-icon="mdi-camera"
                                    label="Upload a picture"
                                    v-model="file"
                                    :rules="rules"
                                    accept="image/*"
                                    id="file"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <v-btn
                                    fab
                                    small
                                    color="primary"
                                    @click="onSubmit"

                                >
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// axios import
import axios from 'axios'
  export default {
    name: 'UploadPic',
    // watch deep object
    data: () => ({
        file: null,
        rules: [
            v => !!v || 'Required',
            v => (v && v.size <= 5000000) || 'File must be less than 5mb'
        ]
    }),
    methods: {
      onSubmit() {
        //   save picture to local storage as picture
        // convert to base64 string
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = (e) => {
          this.picture = e.target.result
        let img = new FormData();
        img.append('image', this.picture.split(',')[1]);
        axios({
            method: 'post',
            url: 'https://api.imgbb.com/1/upload?key=39135dababc897b677b17246b9facdc4',
            data: img,
            headers: {'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            // save picture url to local storage
            this.$store.state.userData.picture = response.data.data.url
            localStorage.setItem('userData', JSON.stringify(this.$store.state.userData));
            // emit event to parent
            this.$emit('save', this.$store.state.userData)
        }, (error) => {
            console.log(error);
        });
        }
      }
    }
  }
</script>
