<template>
<!-- style the background color to grey -->
  <v-app style="background-color:lightgrey">
    <v-app-bar
      app
      color="primary"
      dark
      height="65px"
    >
      <div class="d-flex align-center">
        <v-btn @click="toggleFullscreen" text color="transparent">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/passbook.png"
            transition="scale-transition"
            width="50"
          />
        </v-btn>
        <h2>My PassBook</h2>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="settingsDialog = !settingsDialog"
        text
        fab
        :disabled='!$store.state.licenced'
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-dialog
        v-model="!$store.state.licenced"
        fullscreen 
        persistent :overlay="false"
        transition="dialog-transition"
      >
        <v-card>
          <!-- login card -->
          <v-card-text class="pa-5 text-center">
            <h1>My PassBook</h1>
            <h2 class="mt-4">Login to your account</h2>
            <v-row>
              <v-col>
                <v-text-field v-model="username" label="Username" type="text" hint="Enter your username" persistent-hint class="pa-4"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="password" label="Password" type="password" hint="Enter your password" persistent-hint class="pa-4"></v-text-field>
              </v-col>
            </v-row>
            <h4 class="red--text pa-2">
              {{errorMsg}}
            </h4>
          </v-card-text>
          <v-card-actions class="pa-5 text-center justify-center">
            <v-btn @click="login" color="primary">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay v-model='loading'>
        <v-progress-circular size="300" indeterminate></v-progress-circular>
      </v-overlay>
      <v-dialog
        v-model="impexDialog"
        scrollable fullscreen 
        transition="dialog-transition"
      >
        <v-card>
        <v-card-text v-if="!showLoader" class="pa-5 text-center">
          <h1>{{impexAction ? "Import" : "Export" }}</h1>
          <div>
            <h2 class="mt-4">{{impexAction?"Import your data":"Export your Data"}}</h2>
            <v-textarea v-model="impexText" label="Data" hint="Paste your data here" persistent-hint class="pa-4"></v-textarea>
            <v-btn class='mx-1' @click="impexAction ? importData() : exportData()">
              {{impexAction?"Import":"Export"}}
            </v-btn>
            <v-btn class='mx-1' @click="impexDialog=false">
              Close
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else-if="done!==true" class="pa-5 text-center">
          <h1>Recovering pass...
          </h1>
          <h1 class="pt-5">
            {{$store.state.loaderValue}}%
          </h1>
        </v-card-text>
        <v-card-text v-if="done" class="pa-5 text-center">
          <h2>Successfully recovered pass</h2>
          <v-row class="pt-8">
            <v-col>
            <v-btn @click="showRecoveryDialog = false;$store.state.loaderValue=0;done=false;showLoader= false">
              OK
            </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-progress-linear v-if="showLoader && done==false" :value="$store.state.loaderValue"></v-progress-linear>
      </v-card>
      </v-dialog>
      <!-- <v-dialog v-model='licenceDialog'>
        <v-card class="text-center">
          <v-text-field v-model="licenceKey" label="Licence Key" type="password" hint="Enter your licence key" persistent-hint class="pa-4"></v-text-field>
          <h4 v-if="invalidLicence" class="red--text pa-2">
            {{errorMsg}}
          </h4>
          <v-btn class="ma-2" @click="checkLicence">
            Submit
          </v-btn>
        </v-card>
      </v-dialog> -->
      <v-dialog
        v-model="showRecoveryDialog"
        transition="dialog-transition"
      >
      <v-card>
        <v-card-text v-if="!showLoader" class="pa-5 text-center">
          <h2>Recently deleted pass found.</h2>
          <h3>Do you want to recover it?</h3>
          <v-row class="pt-8">
            <v-col>
            <v-btn @click="randomizeLoader">
              Yes
            </v-btn>
            </v-col>
            <v-col>
            <v-btn @click="showRecoveryDialog=false">
              No
            </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="done!==true" class="pa-5 text-center">
          <h1>Recovering pass...
          </h1>
          <h1 class="pt-5">
            {{$store.state.loaderValue}}%
          </h1>
        </v-card-text>
        <v-card-text v-if="done" class="pa-5 text-center">
          <h2>Successfully recovered pass</h2>
          <v-row class="pt-8">
            <v-col>
            <v-btn @click="showRecoveryDialog = false;$store.state.loaderValue=0;done=false;showLoader= false">
              OK
            </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-progress-linear v-if="showLoader && done==false" :value="$store.state.loaderValue"></v-progress-linear>
      </v-card>
      </v-dialog>
      <v-dialog  height='90vh' v-model="settingsDialog">
        <v-card height="100%" class="py-2">
          <v-card-text style="height:100%" class="text-center d-flex flex-column justify-space-between">
            <div>
              <h2 class="pa-2">Settings</h2>
              <h3 class="pt-5">Delete All Passes</h3>
              <v-btn class="mt-2" @click="$store.state.showPass=false;settingsDialog=false">
                <v-icon>mdi-delete</v-icon>
                Delete
              </v-btn>
              <!-- <h3 class="pt-5">Dark Mode (Beta)</h3> -->
              <div class="mx-auto d-flex justify-center" >
                <v-switch label="Dark Mode" v-model="$vuetify.theme.dark"></v-switch>
              </div>
              <!-- <h3 class="pt-5">Show QR Code</h3> -->
              <div class="mx-auto d-flex justify-center" >
                <v-switch label="Show QR" v-model="$store.state.showQR"></v-switch>
              </div>
              <!-- <h3 class="pt-5">Hide Add Button</h3> -->
              <div class="mx-auto d-flex justify-center" >
                <v-switch label="Hide Add Button" v-model="$store.state.hideAddButton"></v-switch>
              </div>
              <!-- <h3 class="pt-5">Fullscreen</h3> -->
              <div class="mx-auto d-flex justify-center" >
                <v-switch label="Fullscreen Mode" v-model="fullscreen"></v-switch>
              </div>
              
              <!-- <h3 class="pt-5">Fullscreen</h3> -->
              <div class="mx-auto d-flex justify-center" >
                <v-btn @click="impexDialog=true;impexAction=true" class='mx-1' color="success">Import</v-btn>
                <v-btn @click="impexDialog=true;impexAction=false" class='mx-1' color="secondary">Export</v-btn>
              </div>
              
              <div class="mx-auto d-flex justify-center mt-5" >
                <v-btn @click="signOut" color="error">Sign Out</v-btn>
              </div>
            </div>
            <div class="mt-2">
              <h4> Developed by DevRiver
              <v-btn fab x-small color="transparent" elevation="0" @click="$store.state.namesDialog=true;settingsDialog=false">
                <v-icon class="ma-0 pa-0">mdi-copyright</v-icon>
              </v-btn>
              </h4>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- <Pass v-if="$store.state.licenced" v-show='$store.state.showPass' /> and handle the emmited save event -->
      <Pass v-if="$store.state.licenced" v-show='$store.state.showPass' @save="savePass" />
      <div v-else>
        <v-card
        tile
        height="94vh"
        class="pa-2 d-flex justify-center align-center"
        >
          <v-card-text class="text-center">
          <h2>You need a license to use this app</h2>
          <!-- <v-btn class="mt-4" @click="licenceDialog=true">
            <v-icon>mdi-key</v-icon>
            Add License
          </v-btn> -->
          </v-card-text>
        </v-card>
      </div>
      <v-btn v-if="!$store.state.hideAddButton" :disabled='!$store.state.licenced' bottom right fab fixed @click="showRecoveryDialog=true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-main>
  </v-app>
</template>

<script>
import Pass from './components/Pass';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_EiRSrtNwk',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '4k86cd4f8hc3kheqlek26lsbu6',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,


    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});


// You can get the current config object
const currentConfig = Auth.configure();

export default {
  name: 'App',

  components: {
    Pass,
  },

  data: () => ({
    settingsDialog: false,
    showRecoveryDialog: false,
    showLoader: false,
    done:false,
    // licenceDialog:false,
    licenceKey: '',
    invalidLicence: false,
    loading: false,
    fullscreen: false,
    impexDialog: false,
    impexAction: null,
    impexText: '',
    username: '',
    password: '',
    errorMsg: '',
  }),
  watch: {
    fullscreen(newValue) {
      !newValue ? this.closeFullscreen() : this.openFullscreen()
    }
  },
  methods: {
    savePass(pass) {
      this.setUserData();
    },
    async login() {
      try {
        await Auth.signIn(this.username, this.password);
        this.$store.state.licenced = true;
        let user = await Auth.currentAuthenticatedUser();
        console.log(user);
      } catch (error) {
        this.errorMsg = error.message;
      }
    },
    async getUserData() {
      try {
        let user = await Auth.currentAuthenticatedUser();
        let data = JSON.parse(user.attributes['custom:data']);
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        this.$store.state.userData = data
        localStorage.setItem('userData', JSON.stringify(data));
      } catch (error) {
        console.error(error);
        this.errorMsg = error.message;
      }
    },
    async setUserData() {
      try {
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          // stringify user data from store
          'custom:data': JSON.stringify(localStorage.getItem('userData')),
        });
      } catch (error) {
        this.errorMsg = error.message;
      }
    },
    randomizeLoader() {
      this.showLoader = true;
      // increase the number from 1 to 100 with random delays and random amounts
      // use timeouts to increase the value with a random value from 0 to 100
        setTimeout(() => {
          this.$store.state.loaderValue += 14;
        }, 400)
        setTimeout(() => {
          this.$store.state.loaderValue += 19;
        }, 900)
        setTimeout(() => {
          this.$store.state.loaderValue += 25;
        }, 1799)
        setTimeout(() => {
          this.$store.state.loaderValue += 42;
        }, 2800)
        setTimeout(() => {
          this.$store.state.loaderValue = 100;
        }, 4600)
        setTimeout(() => {
          this.done = true;
          this.$store.state.showPass = true;
        }, 4800)
    },
    checkLogin(){
      // check if the user is logged in with amplify and hide the login dialog
      Auth.currentAuthenticatedUser().then(user => {
        this.$store.state.licenced = true;
        this.licenceDialog = false;
        localStorage.setItem('licence', this.licenceKey);
        this.licenceDialog = false;
        console.log('User data', user);
      }).catch(err => {
        console.log(err);
      });
    },
    checkLicence() {
      // check if the licence is valid by looking for it in the dictionary in assets/licences.json
      // if it is valid, set the licence to the licence and show the pass
      // if it is not valid, show an error message
      // import the dictionary
      this.loading = true
      this.licenceDialog = false;
      // axios.get('https://passbook-el.herokuapp.com/'+this.licenceKey).then(response => {
      //   var res = response.data;
      //   if (res == 'Valid') {
      //     this.$store.state.licenced = true;
      //     localStorage.setItem('licence', this.licenceKey);
      //     this.licenceDialog = false;
      //     this.errorMsg = 'Licence '+res;
      //   } else {
      //     this.invalidLicence = true;
      //     this.licenceDialog = true
      //     this.errorMsg = 'Licence '+res;
      //   }
      // }).finally(() => {
      //   this.loading = false
      // }).catch(error => {
      //   console.log(error);
      // })
      const licences = require('@/assets/licences.json');
      // check if the licence is in the dictionary
      var confirmed = Object.values(licences).includes(this.licenceKey);
      this.$store.state.licenced = confirmed;
      if (confirmed){
        this.licenceDialog = false;
        // check if licence in local storage else add it
        if (localStorage.getItem('licence') === null){
          localStorage.setItem('licence', this.licenceKey);
        }
      }
      else{
        this.invalidLicence=true;
      }
      this.loading = false;
    },
    signOut() {
      Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
      this.$store.state.licenced = false;
    },
    setFullscreen () {
      this.fullscreen ? this.closeFullscreen() : this.openFullscreen()
      this.fullscreen = !this.fullscreen
    },
    openFullscreen () {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.webkitRequestFullscreen) { /* Safari */
        document.documentElement.webkitRequestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) { /* IE11 */
        document.documentElement.msRequestFullscreen()
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen()
      }
    },
    toggleFullscreen () {
      this.fullscreen = !this.fullscreen
    },
    exportData(){
      console.log('exporting data');
      // get userData from localstorage, stringify it convert to base64 and show it
      var userData = localStorage.getItem('userData');
      this.impexText = userData;
    },
    importData(){
      if (this.impexText.length > 100){
        // set localStorage after decoding the base64 string
        localStorage.setItem('userData', this.impexText);
        this.data = JSON.parse(this.impexText)
        // force data to update
        // refresh page
        window.location.reload();
        this.impexDialog = false;
      }
    }
  },
  mounted () {
    // check local storage for licence and verify it
      this.checkLogin();
      this.getUserData();
  },
};
</script>
