import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    namesDialog:false,
    showPass:true,
    loaderValue:0,
    picture:null,
    showQR:true,
    licenced:false,
    hideAddButton:false,
    userData:{
      studentType: 'ΠΡΟΠΤΥΧΙΑΚΟΣ ΦΟΙΤΗΤΗΣ',
      creationDate:"19.03.2022 14:27",
      nameGR: 'ΓΕΩΡΓΙΟΣ ΤΣΟΠΑΝΟΠΟΥΛΟΣ',
      nameEN: 'GEORGIOS TSONOPANOPOULOS',
      am: '3135123',
      from: '10/2018',
      to: '08/2025',
      residence: 'Δ. ΠΕΤΡΟΥΠΟΛΗΣ / Π.ΑΤΤΙΚΗΣ',
      uniAddr:'Δ. ΑΙΓΑΛΕΩ / Π. ΑΤΤΙΚΗΣ',
      uniDept:'ΗΛΕΚΤΡΟΛΟΓΩΝ ΚΑΙ ΗΛΕΚΤΡΟΝΙΚΩΝ ΜΗΧΑΝΙΚΩΝ',
      studentId: '212804001421',
      uni:'pada.jpg'
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
